const state = ({
    user: {
        'id': '',
        'username': '',
        'email': ''
    },
    token: {
        'value': '',
        'type': '',
        'expires_at': ''
    },
    authendicated: false
});

const mutations = ({
    SET_AUTH_USER: (state, data) => {
        state.user = data;
    },
    SET_TOKEN: (state, token) => {
        state.token = token;
    },
    SET_AUTH: (state, auth) => {
        state.authendicated = auth;
    }
});

const actions = ({
    SET_AUTH_USER: ({commit}, data) => {
        commit('SET_AUTH_USER', data);
    },
    SET_TOKEN: ({commit}, token) => {
        commit('SET_TOKEN', token);
    },
    SET_AUTH: ({commit}, auth) => {
        commit('SET_AUTH', auth);
    }
});

const getters = ({
    GET_AUTH_USER: state => state.user,
    GET_TOKEN: state => state.token,
    GET_AUTH: state => state.authendicated,
});

const authModule = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}

export default authModule;


/*
const auth = ({
    state: {
        user: {
            'id': '',
            'username': '',
            'email': ''
        },
        token: {
            'value': '',
            'type': '',
            'expires_at': ''
        },
        authendicated: false
    },
    mutations: {
        SET_AUTH_USER: (state, data) => {
            state.user = data;
        },
        SET_TOKEN: (state, token) => {
            state.token = token;
        },
        SET_AUTH: (state, auth) => {
            state.authendicated = auth;
        }
    },
    actions: {
        SET_AUTH_USER: ({commit}, data) => {
            commit('SET_AUTH_USER', data);
        },
        SET_TOKEN: ({commit}, token) => {
            commit('SET_TOKEN', token);
        },
        SET_AUTH: ({commit}, auth) => {
            commit('SET_AUTH', auth);
        }
    },
    getters: {
        GET_AUTH_USER: state => state.user,
        GET_TOKEN: state => state.token,
        GET_AUTH: state => state.authendicated,
    }
})

export default auth;
 */
