import axios from 'axios';

let baseApi = axios.create({
    baseURL: process.env.VUE_APP_API_BASE
})

const Api = function () {
    let token = localStorage.getItem('token');

    if (token) {
        baseApi.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    }

    return baseApi;
}

export default Api;

