import baseApi from '@/baseApi';
import store from '@/store';

export default ({
    userDefault: {
        id: '',
        email: '',
        username: '',
        token: ''
    },
    async authCheck(){
        if ( !localStorage.getItem('token') ) return;
        await baseApi().get('/user').then((response) => {
            if (response.data.success) {
                store.dispatch('Auth/SET_AUTH_USER', response.data.user.user);
                store.dispatch('Auth/SET_TOKEN', response.data.user.token);
                store.dispatch('Auth/SET_AUTH', true);
                localStorage.setItem('token', response.data.user.token.value);
            }
        })
    }
});

