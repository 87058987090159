import baseApi from '@/baseApi';

const state = {
    workday: {
        starttime: null,
        endtime: null,
        workentries: []
    },
    test: 'jo'
}

const mutations = {
    SET_WORKDAY: (state, data) => {
        state.workday = data;
    },
    END_WORKDAY: (state, data) => {
        state.workday.endtime = data.endtime;
    },
    ADD_ENTRY: (state, data) => {
        console.log('ADD_ENTRY mutation');
        console.log(state);
        console.log(data);
        state.workday.workentries.push(data);
    }
}

const actions = {
    SET_WORKDAY: ({commit}, data) => {
        console.log(data);
        commit('SET_WORKDAY', data);
    },
    GET_WORKDAY: ({commit}) => {
        console.log('GET_WORKDAY');
        baseApi().get('workday').then((response) => {
            if (response.data.success) {
                commit('SET_WORKDAY', response.data.workday);
            }
        });
    },
    START_WORKDAY: ({commit}) => {
        const data = {
            'starttime': Math.round(Date.now() / 1000)
        }
        console.log('START_WORKDAY');
        baseApi().post('workday/start', data).then((response) => {
            if ( response.data.success ) {
                commit('SET_WORKDAY', response.data.workday);
            }
        })
    },
    END_WORKDAY: ({commit}) => {
        const data = {
            'endtime': Math.round(Date.now() / 1000)
        }
        console.log('END_WORKDAY');
        baseApi().post('workday/end', data).then((response) => {
            if ( response.data.success ) {
                commit('END_WORKDAY', response.data.workday);
            }
        })
    },

    /*
    async START_WORKDAY ({ commit }) {
        const data = {
            'starttime': Date.now()
        }
        console.log('START_WORKDAY');
        await baseApi().post('workday/start', data).then((response) => {
            if ( response.data.success ) {
                commit('SET_WORKDAY', response.data.workday);
            }
        })
    },
     */
}

const getters = {
    GET_WORKDAY: state => state.workday,
}

const workdayModule = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}

export default workdayModule;
