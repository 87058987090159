<template>
    <div class="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div class="bg-white rounded-lg shadow-lg max-w-md w-full py-12 px-8 overflow-hidden">
            <h2 class="text-center text-3xl font-extrabold text-gray-900">Create your account</h2>
            <p class="text-center text-sm text-gray-600">Or <router-link to="/login" class="font-medium text-green-900 hover:text-green-700">or sign in</router-link></p>
            <form action="#" method="POST">
                <input type="hidden" name="remember" value="true">
                <div class="rounded-md shadow-sm mt-6 mb-4">
                    <div>
                        <label for="username" class="sr-only">Username</label>
                        <input type="text" id="username" placeholder="Username" required class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" v-model="cred.username">
                    </div>
                    <div>
                        <label for="email-address" class="sr-only">Email address</label>
                        <input id="email-address" name="email" type="email" autocomplete="email" required class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Email address" v-model="cred.email">
                    </div>
                    <div>
                        <label for="password" class="sr-only">Password</label>
                        <input id="password" name="password" type="password" autocomplete="current-password" required class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Password" v-model="cred.password">
                    </div>
                </div>

                <div>
                    <button type="submit" class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-900 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" @click="register($event)">Create your account</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import baseApi from '@/baseApi';

export default {
    name: 'Login',
    data() {
        return {
            cred: {
                username: '',
                email: '',
                password: ''
            }
        }
    },
    methods: {

        /**
         * Register a new user.
         * @TODO Do something with the repsonse. ;)
         *
         * @param event
         */
        register(event) {
            event.preventDefault();
            baseApi().post('/auth/register', this.cred).then((response) => {
            })
        }
    }
}
</script>
