require('dotenv').config();

import { createApp } from 'vue'
import Vuex from "vuex";
import App from './App.vue'
import store from './store'
//import './index.css'
import router from './router'
import auth from '@/auth';
import './index.css'

auth.authCheck();
//auth.getWorkday();

const app = createApp(App).use(router)
    .use(Vuex)
    .use(store)
    .mount('#app')
