<template>
    <div class="about">
        <h1>This is an about page</h1>
    </div>
</template>


<script>
/* eslint-disable no-unused-vars */
import axios from 'axios';
import {mapGetters} from 'vuex';
import index from '@/auth';

export default {
    name: 'Settings',
    components: {},
    mounted() {
        //console.log(this.$store.state.index);
    }
}
</script>
