<template>
    <div class="main">
        <header class="main__header">
            <div id="nav">
                <ul v-if="isAuth">
                    <router-link to="/">Home</router-link> |
                    <router-link to="/dashboard">Dashboard</router-link> |
                    <router-link to="/settings">Settings</router-link> |
                    <a href="javascript:void(0);" @click="logout">Logout</a>
                </ul>
                <ul v-else>
                    <router-link to="/">Home</router-link> |
                    <router-link to="/dashboard">Dashboard</router-link> |
                    <router-link to="/settings">Settings</router-link> |
                    <router-link to="/register">Register</router-link> |
                    <router-link to="/login">Login</router-link>
                </ul>
            </div>
        </header>
        <main class="main__main">
            <router-view/>
        </main>
        <footer class="main__footer">
            <div class="text-center font-light text-gray-400 py-4">made with 🖤 in berlin</div>
        </footer>
    </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import axios from 'axios';
import { mapGetters, mapState } from 'vuex';
import auth from '@/auth';
import baseApi from '@/baseApi';
import store from '@/store';
import router from '@/router';

export default {
    name: 'App',
    components: {},
    data(){
        return {
            settings: {
                workday: '08:00'
            },
            newEntry: ''
        }
    },
    created(){
        //auth.authCheck();

        /*
        axios.get('https://api-lumen.timest.local/api/1/workdays/current')
            .then(function (response) {
                if (response.data.workday) {
                    this.workdayStarted = true
                }
            })

         */
    },
    computed: {
        ...mapGetters({
            isAuth: 'Auth/GET_AUTH',
            user: 'Auth/GET_AUTH_USER'
        })
    },
    methods: {
        logout(){
            baseApi().post('/auth/logout').then((response) => {
                if (response.data.success) {
                    store.dispatch('Auth/SET_AUTH_USER', null);
                    store.dispatch('Auth/SET_TOKEN', '');
                    store.dispatch('Auth/SET_AUTH', false);
                    localStorage.removeItem('token');
                    router.push('/');
                }
            });
        }
    }
}
</script>


<style>
</style>
