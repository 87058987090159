import baseApi from '@/baseApi';

const state = {
}

const mutations = {
}

const actions = {
    async ADD_ENTRY ({commit}, data) {
        console.log(data);
        await baseApi().post('workentry/add', data).then((response) => {
            console.log(response.data);
            if ( response.data.success ) {
                commit('Workday/ADD_ENTRY', response.data.workentry, {
                    root: true
                })
            }
        })
    }
}

const getters = {}

const workentryModule = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}

export default workentryModule;
