import Vuex from "vuex";
import authModule from './modules/auth';
import workdayModule from './modules/workday'
import workentryModule from './modules/workentry'

const store = new Vuex.Store({
    isLoading: true,
    modules: {
        Auth: authModule,
        Workday: workdayModule,
        Workentry: workentryModule
    },
    state: {
        workdayStarted: false,
        timings: {
            left: 28800,
            spent: 0,
            slacking: 0
        }
    },
    mutations: {

    },
    actions: {
        startWorkday() {
        },
        endWorkday() {
            //axios.patch('https://api-lumen.timest.local/api/1/workdays/end')
        }
    },
    getters: {

    }
});

export default store;
