<template>
    <div class="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div class="w-6/12">
            <div class="bg-white rounded-lg shadow-lg w-full overflow-hidden">
                <div class="flex">
                    <aside class="w-16 flex flex-col items-center bg-white text-gray-700 shadow">
                        <!-- Side Nav Bar-->

                        <div class="h-16 flex items-center w-full">
                            <!-- Logo Section -->
                            <a class="h-6 w-6 mx-auto" href="http://svelte.dev/">
                                <img class="h-6 w-6 mx-auto" src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/1b/Svelte_Logo.svg/512px-Svelte_Logo.svg.png" alt="svelte logo"/>
                            </a>
                        </div>


                        <div class="mt-auto h-16 flex items-center w-full">
                            <!-- Action Section -->
                            <button class="h-16 w-10 mx-auto flex flex justify-center items-center w-full focus:text-orange-500 hover:bg-red-200 focus:outline-none">
                                <svg class="h-5 w-5 text-red-700" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                    <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                                    <polyline points="16 17 21 12 16 7"></polyline>
                                    <line x1="21" y1="12" x2="9" y2="12"></line>
                                </svg>
                            </button>
                        </div>

                    </aside>
                    <main class="w-full bg-gray-50">
                        <div class="mx-3 my-3">
                            <div class="flex -mx-2">
                                <div class="w-1/4 mx-2">
                                    <div class="bg-white shadow rounded px-4 py-2">
                                        <div class="text-base font-normal text-gray-600">time left</div>
                                        <div class="text-xl font-bold text-gray-800 -mt-1">{{ sec2time(timeLeft) }}</div>
                                    </div>
                                </div>
                                <div class="w-1/4 mx-2">
                                    <div class="bg-white shadow rounded px-4 py-2">
                                        <div class="text-base font-normal text-gray-600">time spent</div>
                                        <div class="text-xl font-bold text-gray-800 -mt-1">{{ sec2time(timeSpentTotal) }}</div>
                                    </div>
                                </div>
                                <div class="w-1/4 mx-2">
                                    <div class="bg-white shadow rounded px-4 py-2">
                                        <div class="text-base font-normal text-gray-600">time slacked</div>
                                        <div class="text-xl font-bold text-gray-800 -mt-1">{{ sec2time(timeSlacked) }}</div>
                                    </div>
                                </div>
                                <div class="w-1/4 mx-2 self-center">
                                    <div class="">
                                        <button class="w-full bg-green-900 text-white rounded-3xl py-3" @click="startWorkday()" v-if="!workday.starttime">Start your day</button>
                                        <button class="w-full bg-green-900 text-white rounded-3xl py-3" @click="endWorkday()" v-if="workday.starttime">End your day</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mx-3 my-3 h-80">
                            <div v-if="!workday.starttime">
                                <p>Dein Tag hat noch nicht begonnen.</p>
                            </div>

                            <div class="bg-white shadow rounded px-4 py-2 h-full overflow-scroll" v-if="workday.starttime">
                                <ul class="app__entries">
                                    <li class="font-bold border-b text-sm text-gray-700 py-2" v-if="workday.starttime">Your workday started at
                                        {{ toLocaleString(workday.starttime) }}
                                    </li>
                                    <li class="border-b text-sm text-gray-700 py-2" v-for="(entry, index) in workday.workentries" :key="entry">
                                        {{ toLocaleTimeString(entry.timestamp) }} |
                                        {{ timestampRelativeToElementBefore(entry.timestamp, index) }} | {{
                                            entry.title
                                        }}
                                    </li>
                                    <li class="font-bold border-b text-sm text-gray-700 py-2" v-if="workday.endtime">Your workday endet at {{
                                            toLocaleString(workday.endtime)
                                        }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="mx-3 my-3">
                            <div class="relative">
                                <div class="flex relative z-0">
                                    <span class="rounded-l-md inline-flex items-center justify-center border-t bg-white border-l border-b border-gray-300 text-gray-500 shadow-sm text-sm w-20">{{ sec2time(timeSpent) }}</span>
                                    <input type="text" class="rounded-r-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none  focus:ring-purple-600 " v-model="entry" @keyup.enter="addEntry" @focusin="focusin()" @focusout="focusout()"  />
                                </div>
                                <div class="absolute z-10 w-full h-full top-0 left-0 bg-white opacity-60 cursor-not-allowed" v-if="!showInput"></div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import baseApi from '@/baseApi';
import store from '@/store';
import {mapGetters, mapActions} from "vuex";

export default {
    name: 'Dashboard',
    components: {},
    data() {
        return {
            entry: '',
            spent: 0,
            //timeLeft: 0,
            timeSpent: 0,
            //timeSpentTotal: 0,
            //timeSlacked: 0
        }
    },
    computed: {
        ...mapGetters({
            workday: 'Workday/GET_WORKDAY'
        }),
        lastTimestamp() {
            if (this.workday.workentries.length === 0) return this.workday.starttime;
            return this.workday.workentries[this.workday.workentries.length - 1].timestamp;
        },
        timeSpentTotal(){
            let differenceTotal = 0;
            if (this.workday.workentries.length === 0) return 0;

            for (let index = 0; index < this.workday.workentries.length; index++) {
                let difference = 0;
                if ( this.workday.workentries[index].type !== 1 ) continue;

                if ( index === 0 ) {
                    difference = this.workday.workentries[index].timestamp - this.workday.starttime;
                } else {
                    difference = this.workday.workentries[index].timestamp - this.workday.workentries[index-1].timestamp;
                }

                differenceTotal += difference;
            }

            return differenceTotal;
        },
        timeLeft(){
            let differenceTotal = 28800;
            if (this.workday.workentries.length === 0) return 0;

            for (let index = 0; index < this.workday.workentries.length; index++) {
                let difference = 0;
                if ( this.workday.workentries[index].type === 3 ) continue;

                if ( index === 0 ) {
                    difference = this.workday.workentries[index].timestamp - this.workday.starttime;
                } else {
                    difference = this.workday.workentries[index].timestamp - this.workday.workentries[index-1].timestamp;
                }

                differenceTotal -= difference;
            }

            if ( differenceTotal < 0 ) {
                return 0;
            }

            return differenceTotal;
        },
        timeSlacked(){
            let differenceTotal = 0;
            if (this.workday.workentries.length === 0) return 0;

            for (let index = 0; index < this.workday.workentries.length; index++) {
                let difference = 0;
                if ( this.workday.workentries[index].type !== 2 ) continue;

                if ( index === 0 ) {
                    difference = this.workday.workentries[index].timestamp - this.workday.starttime;
                } else {
                    difference = this.workday.workentries[index].timestamp - this.workday.workentries[index-1].timestamp;
                }

                differenceTotal += difference;
            }

            return differenceTotal;
        },
        workdayStatus(){
            /**
             * 0 = workday not started
             * 1 = workday started
             * 2 = workday ended
             */

            if ( this.workday.starttime && this.workday.endtime ) {
                return 2;
            }

            if ( this.workday.starttime && !this.workday.endtime ) {
                return 1;
            }

            return 0;
        },
        showInput(){
            if ( this.workdayStatus === 1 ) {
                return true;
            }

            return false;
        }
    },
    methods: {
        startWorkday() {
            this.$store.dispatch('Workday/START_WORKDAY');
        },
        endWorkday() {
            this.$store.dispatch('Workday/END_WORKDAY');
        },
        toLocaleString(timestamp) {
            const date = new Date(timestamp * 1000);
            return date.toLocaleString();
        },
        toLocaleDateString(timestamp) {
            const date = new Date(timestamp * 1000);
            return date.toLocaleDateString();
        },
        toLocaleTimeString(timestamp) {
            const date = new Date(timestamp * 1000);
            return date.toLocaleTimeString();
        },
        sec2time(timeInSeconds) {
            const pad = function (num, size) {
                    return ('000' + num).slice(size * -1);
                },
                time = parseFloat(timeInSeconds).toFixed(3),
                hours = Math.floor(time / 60 / 60),
                minutes = Math.floor(time / 60) % 60,
                seconds = Math.floor(time - minutes * 60),
                milliseconds = time.slice(-3);

            return pad(hours, 2) + ':' + pad(minutes, 2) + ':' + pad(seconds, 2);
        },
        timestampRelativeToElementBefore(timestamp, index) {
            if (index === 0) {
                return this.sec2time(timestamp - this.$store.state.Workday.workday.starttime);
            }
            return this.sec2time(timestamp - this.$store.state.Workday.workday.workentries[index - 1].timestamp);
        },
        addEntry() {
            let entryType = null;
            if (this.entry.trim().length === 0) return;

            if (this.entry.substr(-3) === '***') {
                entryType = 3;
            } else if (this.entry.substr(-2) === '**') {
                entryType = 2;
            } else {
                entryType = 1;
            }

            this.$store.dispatch('Workentry/ADD_ENTRY', {
                timestamp: Math.floor(Date.now() / 1000),
                type: entryType,
                title: this.entry
            });

            this.entry = '';
        },
        setTimeLeft(){
            const now = Math.round(Date.now() / 1000);

            if ( this.workday.workentries.length === 0 ) {
                this.timeLeft = 28800;
                return true;
            }

            for (let index = 0; index < this.workday.workentries.length; index++) {
                if ( this.workday.workentries[index].type !== 1 ) continue;
                const lastTimestamp = index === 0 ? this.workday.starttime : this.workday.workentries[this.workday.workentries.length - 1].timestamp;
                this.timeLeft -= now - lastTimestamp;
                return true;
            }

            // otherwise calculate here please

            this.timeLeft = 28800;
        },
        focusin: function(){
            const lastTimestamp = this.lastTimestamp;
            const now = Math.round(Date.now() / 1000);
            this.timeSpent = now - lastTimestamp;
        },
        focusout: function(){
            this.timeSpent = 0;
        }
    },
    watch(){

    },
    mounted() {
        if (!this.workday.starttime) {
            this.$store.dispatch('Workday/GET_WORKDAY');
        }

        this.setTimeLeft();
    },
}
</script>
