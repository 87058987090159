import {createRouter, createWebHashHistory} from 'vue-router'
import Home from '../views/Home.vue'
import Register from '../views/Register.vue'
import Login from '../views/Login.vue'
import Dashboard from '../views/Dashboard.vue'
import Settings from '../views/Settings.vue'
import store from '@/store';

const routes = [
    {
        path: '/',
        name: 'Login',
        component: Home
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    },
    {
        path: '/register',
        name: 'Register',
        component: Register
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/settings',
        name: 'Settings',
        component: Settings,
        meta: {
            requiresAuth: true
        }
    },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    const user = store.state.Auth.user;
    const authendicated = store.state.Auth.authendicated;
    const isRequiredAuth = to.matched.some(r => r.meta.requiresAuth);

    // if required route and user isnt authorized
    if ( isRequiredAuth && !authendicated ) return next('/login');

    if(!store.state.Auth.token){
        const token = localStorage.getItem("token");
        if(token){
            const data = {
                token:token
            };
            store.commit('auth/saveTokenData',data);
        }
    }
    //const auth = store.getters["auth/isTokenActive"];

    if(to.fullPath === "/"){
        //return next();
    }
    else if(authendicated && !to.meta.requiredAuth){
        //return next({path:"/dashboard"});
    }
    else if(!authendicated && to.meta.requiredAuth){
        //return next({path: '/login'});
    }

    return next();
});

/*
router.beforeEach((to, from, next) => {
    if(to.matched.some(record => record.meta.requiresAuth)) {
        if (store.state.isAuthenticated) {
            next()
            return
        }
        next('/login')
    } else {
        next()
    }
})
*/

export default router
